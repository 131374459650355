import React from "react"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Title from "./Title"

const Link = ({ title, url, description, language }) => (

  <Col md={6} >
    <a href={ url} target="_blank" rel="noopener noreferrer" >
      <Card>
        <Card.Body>
          <Card.Title>{ title }</Card.Title>
          <Card.Text>{ description }</Card.Text>
        </Card.Body>
        <Card.Footer><code>{ language }</code></Card.Footer>
      </Card>
    </a>
  </Col>
)

const Projects = () => {

  return (
    <div className="links">
      <Title pageTitle="Recent Projects" />
      <hr />

      <Container fluid>
        <Row>

          {/*<Link*/}
          {/*  title="SoulSki Photography"*/}
          {/*  url="https://photography.soulski.ca"*/}
          {/*  description="Fine art photography."*/}
          {/*  language="Gatsby / react.js / node.js"*/}
          {/*/>*/}

          <Link
            title="Elemental Influence"
            url="https://elemental-influence.soulski.ca"
            description="A tabletop game that I am currently developing in Unity."
            language="react.js / C#"
          />

          {/*<Link*/}
          {/*  title="Notes webapp"*/}
          {/*  url="https://notes-webapp.soulski.ca"*/}
          {/*  description="A dynamic web application to organize data."*/}
          {/*  language="react.js / node.js / mongoDB"*/}
          {/*/>*/}

          <Link
            title="Dev notes"
            url="https://notes.soulski.ca"
            description="Notes on various software development topics."
            language="PHP"
          />

          <Link
            title="Felix Ski Service"
            url="http://felixskiservice.com"
            description="The first and still the best ski service."
            language="PHP"
          />

          <Link
            title="School Recipes"
            url="https://g5-recipes.soulski.ca"
            description="A collection of recipes from my grade 5 class. My first web project."
            language="HTML"
          />

        </Row>
      </Container>
    </div>
  )
}

export default Projects