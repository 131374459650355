import React from "react"
import { Link } from "gatsby"
// import { isAuthenticated } from "../utils/auth"

const links = [
  // { label: `Blog`, url: `/blog`},
  // { label: `Projects`, url: `/projects`},

  // { label: `Dashboard`, url: `/dashboard`, isProtected: true},
]

const Navbar = () => {

  return (
    <ul className="navbar-main">
      { links.map( (item, key) => {
        // if( item.isProtected && !isAuthenticated() )
        //   return null
        // else
          return (
          <li key={ key }>
            <Link to={ item.url }>
              { item.label }
            </Link>
          </li>
      )})}
    </ul>
 )
}

export default Navbar