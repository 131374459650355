import React from "react"

import Layout from "../components/layout"
import Projects from "../components/Projects"
import SEO from "../components/seo"
import Bio from "../components/Bio"

// import { useCommonDataQuery } from "../data/useCommonDataQuery"

const IndexPage = () => {

  // const { site } = useCommonDataQuery()
  return (
      <Layout sidebar={ <Bio /> } >
        <SEO title="Home" />
        <Projects />
      </Layout>
  )
}

export default IndexPage
