import React from "react"
import PropTypes from "prop-types"

const Title = ({ pageTitle }) => {

  return (
    <h1 className="page-title" >{ pageTitle }</h1>
  )
}

export default Title

Title.propTypes = {
  pageTitle: PropTypes.string.isRequired
}
