import React from "react"

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const bio = {
  name: "James Defant",
  caption: "Full-Stack Software Developer",
  email: "james@soulski.ca",
  linkedin: "https://www.linkedin.com/in/james-defant",
  github: "https://github.com/jamesdefant"
}

const Bio = () => {

  return (
    <div className="sidebar" >
      <h1 className="name">{ bio.name }</h1>
      <p className="caption">{ bio.caption }</p>
      <hr />
      <p>
        <a href={ `mailto:` + bio.email } title={ bio.email }>
          <MailOutlineIcon />
        </a>
      </p>
      <p>
        <a href={ bio.linkedin } title="Find me on LinkedIn"
           target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
        </a>
      </p>
      <p>
        <a href={ bio.github } title="Find me on GitHub"
           target="_blank" rel="noopener noreferrer">
          <GitHubIcon />
        </a>
      </p>
      <hr/>
      <p className="caption">
        I enjoy coding.<br/>
        I like creating things out of nothing so that I may take them apart and improve upon them.<br/>
        Also, tacos.
      </p>
    </div>
  )
}

export default Bio